.panel.legend-panel {
  width: auto;
  border-radius: 6px;
  max-width: 355px;
  color: #f1f1f1;
  background: rgba(49, 49, 49, 0.8);
  position: relative;
}

.panel.legend-panel .panel-body {
  margin: 10px 5px;
}

.panel-body__legend {
  margin: 10px;
}

.panel-body__legend .btn.panel-button {
  color: rgba(255, 255, 255, 0.65);
  text-decoration: none;
  border: 0;
  text-align: center;
}

.panel-body__legend .btn.panel-button:hover {
  color: rgba(255, 255, 255, 1);
}

.panel-body__legend--button {
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.65);
}

.panel-body__legend--block {
  margin: 15px 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.panel-body__legend--name {
  font-size: 13px;
  margin-left: 5px;
}

.panel-body__legend--color {
  width: 15px;
  height: 15px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 4px;
  border-radius: 30px;
}

.panel-body__legend--color.line {
  height: 0px;
  border-radius: 0px;
}

.panel-body__legend--color.square {
  width: 15px;
  height: 15px;
  border-radius: 0px;
  border-color: #691812 !important;
}

.panel.panel-body {
  width: auto;
}

.editable-legend__color-input {
  cursor: pointer;
}




