.panel {
  background: rgba(255,255,255, 0.8);
  width: 300px;
}

.panel-header {
  margin: 0.5rem;
  text-align: center;
  font-size: 0.8rem;
}

.panel-button {
  margin: 0.5rem;
}

.panel-hide {
  display: none;
}

.maplibregl-ctrl-top-left {
  height: 100%;
}

@media screen and (max-width: 600px) {
  .panel {
    width: 290px!important;
  }
}
